import React, { useEffect } from "react";
import styles from "../../styles/styles";
import CountDown from "./CountDown";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";

const EventCard = ({ active, data }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const addToCartHandler = (data) => {
    const isItemExists = cart && cart.find((i) => i._id === data._id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <motion.div
      data-aos="fade-up"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`w-full block bg-white rounded-lg shadow-lg hover:shadow-2xl ${
        active ? "unset" : "mb-12"
      } lg:flex p-4 transform transition-transform hover:scale-105`}
    >
      <div className="w-full lg:w-[50%] m-auto">
        <img
          src={`${data.images[0]?.url}`}
          alt={data.name}
          className="rounded-lg shadow-md"
        />
      </div>
      <div className="w-full lg:w-[50%] flex flex-col justify-center p-4">
        <h2 className="text-3xl font-extrabold mb-3 text-[#F2703D]">
          {data.name}
        </h2>
        <p className="text-gray-600 mb-4">{data.description}</p>
        <div className="flex py-2 justify-between items-center">
          <div className="flex items-center">
            <h5 className="font-medium text-lg text-[#d55b45] pr-3 line-through">
              {data.originalPrice}
            </h5>
            <h5 className="font-bold text-xl text-[#333] font-Roboto">
              {data.discountPrice}
            </h5>
          </div>
          <span className="pr-3 font-medium text-lg text-[#44a55e]">
            {data.sold_out} sold
          </span>
        </div>
        <div className="my-4 text-center">
          <CountDown
            data={data}
            className="text-2xl font-semibold text-[#F2703D] p-2 bg-gray-100 rounded-md"
          />
        </div>
        <div className="flex items-center">
          <Link to={`/product/${data._id}?isEvent=true`}>
            <div
              className={`${styles.button} text-white bg-[#F2703D] hover:bg-black transition-colors duration-300`}
            >
              See Details
            </div>
          </Link>
          <div
            className={`${styles.button} text-white bg-[#F2703D] hover:bg-black ml-5 transition-colors duration-300`}
            onClick={() => addToCartHandler(data)}
          >
            Add to cart
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default EventCard;
