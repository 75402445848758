// VideoCard.js
import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 10px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  position: relative;
  width: 250px;
  height: 400px;
  margin-right: 15px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LogoContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: bounce 1s infinite;

  img {
    width: 70%;
    height: auto;
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const VideoCard = ({ src, logo }) => {
  return (
    <Card>
      <Video src={src} autoPlay muted loop />
      <LogoContainer>
        <img src={logo} alt="Company Logo" />
      </LogoContainer>
    </Card>
  );
};

const VideoCardsRow = ({ videos }) => {
  return (
    <CardContainer>
      {videos.map((video, index) => (
        <VideoCard key={index} src={video.src} logo={video.logo} />
      ))}
    </CardContainer>
  );
};

export default VideoCardsRow;
