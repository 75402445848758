import React from 'react';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Events from "../components/Events/Events";
import Sponsored from "../components/Route/Sponsored";
import Footer from "../components/Layout/Footer";
import NewArrivalsSliders from '../components/NewArrivalsSliders/NewArrivalsSliders';
import VideoCardsRow from '../components/Shop/Layout/VideoCard';

import Image1 from '../../src/Assests/denzenb1.png';
import Image2 from '../../src/Assests/denzenb2.png';
import Image3 from '../../src/Assests/denzenb3.png';
import Video1 from '../../src/Assests/bannervideo.mp4';
import Logo from '../../src/Assests/Logo.png';

const videos = [
  { src: Video1, logo: Logo, text: 'Amazing Deals on All Brands!' },
  { src: Video1, logo: Logo, text: 'Latest Laptops at Discounted Prices!' },
  { src: Video1, logo: Logo, text: 'Exclusive Offers on High-Performance Laptops!' },
  { src: Video1, logo: Logo, text: 'Amazing Deals on All Brands!' },
  { src: Video1, logo: Logo, text: 'Latest Laptops at Discounted Prices!' },
  { src: Video1, logo: Logo, text: 'Exclusive Offers on High-Performance Laptops!' },
  { src: Video1, logo: Logo, text: 'Amazing Deals on All Brands!' },
  { src: Video1, logo: Logo, text: 'Latest Laptops at Discounted Prices!' },
];

const HomePage = () => {
  const images = [Image1, Image2, Image3];

  return (
    <div>
      <Header activeHeading={1} />
      <Hero />

      <Fade bottom duration={1000}>
        <Categories />
      </Fade>

      <Slide bottom duration={1000}>
        <NewArrivalsSliders images={images} />
      </Slide>

      <Zoom duration={1000}>
        <BestDeals />
      </Zoom>

      <Fade bottom duration={1000}>
        <Events />
      </Fade>

      <Slide bottom duration={1000}>
        <FeaturedProduct />
      </Slide>

      <Zoom duration={1000}>
        <VideoCardsRow videos={videos} />
      </Zoom>

      <Fade bottom duration={1000}>
        <Sponsored />
      </Fade>

      <Fade bottom duration={1000}>
        <Footer />
      </Fade>
    </div>
  );
}

export default HomePage;
