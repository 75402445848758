import React from "react";
import Slider from "react-slick";
import Marquee from "react-fast-marquee";
import IMG1 from "../../../src/Assests/b.jpg";
import IMG2 from "../../../src/Assests/b1.jpg";
import IMG3 from "../../../src/Assests/b2.jpg";
import IMG4 from "../../../src/Assests/b5.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Sponsored = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const images = [IMG1, IMG2, IMG3, IMG4];

  return (
    <div className="w-full px-4 py-8 relative">
      <div className=" w-full overflow-hidden">
        <Marquee speed={50} gradient={false}>
          <span className="text-[105px] font-bold text-[#1C1C1C]">
            Experience the Future with Denzen Computers &nbsp; Enjoy a 1-Year
            Comprehensive Warranty on All Laptops &nbsp; Claim Your Warranty
            with Ease &nbsp;
          </span>
        </Marquee>
      </div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="p-2">
            <img
              src={image}
              alt={`Slide ${index}`}
              className="w-full h-auto object-cover rounded-lg"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Sponsored;
