import React, { useState, useEffect } from "react";
import {
  AiFillHeart,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";

const ProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <>
      <div className="w-full h-[420px] bg-white rounded-lg shadow-sm p-3 relative cursor-pointer hover:shadow-lg transition-all duration-300 hover:bg-gray-100">
        <div className="flex justify-end"></div>
        <Link
          to={`${
            isEvent ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`
          }`}
        >
          <img
            src={`${data.images && data.images[0]?.url}`}
            alt=""
            className="w-full h-[180px] object-cover rounded-md hover:opacity-90 transition-opacity duration-300"
          />
        </Link>
        <Link to={`/shop/preview/${data?.shop._id}`}>
          <h5 className="text-sm font-medium mt-2">{data.shop.name}</h5>
        </Link>
        <Link
          to={`${
            isEvent ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`
          }`}
        >
          <h4 className="pb-3 font-semibold">
            {data.name.length > 40 ? data.name.slice(0, 40) + "..." : data.name}
          </h4>

          <div className="flex">
            <Ratings rating={data?.ratings} />
          </div>

          <div className="py-2 flex items-center justify-between">
            <div className="flex">
              <h5 className="text-lg font-bold text-#000000">
                {data.originalPrice === 0 ? data.originalPrice : data.discountPrice}
              </h5>
              {data.originalPrice && (
                <h4 className="text-sm font-medium line-through ml-2 text-gray-600">
                  {data.originalPrice}$
                </h4>
              )}
            </div>
            <span className="font-[400] text-[17px] text-[#68d284]">
              {data?.sold_out} sold
            </span>
          </div>
        </Link>

        {/* side options */}
        <div className="absolute right-2 top-5">
          {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Add to wishlist"
            />
          )}
          <AiOutlineEye
            size={22}
            className="cursor-pointer mt-3"
            onClick={() => setOpen(!open)}
            color="#333"
            title="Quick view"
          />
          <AiOutlineShoppingCart
            size={25}
            className="cursor-pointer mt-3"
            onClick={() => addToCartHandler(data._id)}
            color="#444"
            title="Add to cart"
          />
        </div>

        {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}

        {/* Buy Now Button */}
        <div className="absolute bottom-3 left-0 right-0 px-3">
          <button
            onClick={() => addToCartHandler(data._id)}
            className="w-full py-2 bg-[#F2703D] text-white rounded-lg hover:bg-[#1C1C1C] transition-all duration-300 hover:shadow-md"
          >
            Buy Now
          </button>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
