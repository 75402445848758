import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Lottie from "react-lottie";
import animationData from "../Assests/animations/107043-success.json";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import VideoCardsRow from "../components/Shop/Layout/VideoCard";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Events from "../components/Events/Events";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import NewArrivalsSliders from "../components/NewArrivalsSliders/NewArrivalsSliders";
const OrderSuccessPage = () => {
  return (
    <div>
          
          <Header />
          <Success />
      <Zoom duration={1000}>
        <BestDeals />
      </Zoom>

      <Fade bottom duration={1000}>
        <Events />
      </Fade>

      <Slide bottom duration={1000}>
        <FeaturedProduct />
      </Slide>


    
      <Footer />
    </div>
  );
};

const Success = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <Lottie options={defaultOptions} width={300} height={300} />
      <h5 className="text-center mb-14 text-[25px] text-[#000000a1]">
        Your order is successful 😍
      </h5>
      <br />
      <br />
      
    
    
    </div>
  );
};

export default OrderSuccessPage;
