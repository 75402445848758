import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const NewArrivalsSliders = ({ images }) => {
    return (
        <div className="relative w-full mx-auto mt-10 px-4 py-8">
            {/* <h2 className="text-4xl font-extrabold text-start mb-6 text-gray-800 animate-fadeIn">
                New Arrivals
            </h2> */}
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={30}
                slidesPerView={1}
                navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }}
                pagination={{ clickable: true }}
                loop={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                className="shadow-lg rounded-lg"
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img
                            src={image}
                            alt={`Slide ${index}`}
                            className="w-full h-[700px] object-cover rounded-lg transition-transform duration-700 ease-in-out transform hover:scale-105"
                            style={{
                                boxShadow: "0px 4px 15px rgba(255, 165, 0, 0.5)",
                            }}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            {/* <div
                className="swiper-button-next absolute right-4 top-1/2 transform -translate-y-1/2 p-3 bg-orange-500 text-white rounded-full shadow-lg transition-transform duration-300 hover:bg-orange-600 hover:scale-110"
                style={{ boxShadow: "0px 4px 10px rgba(255, 165, 0, 0.8)" }}
            >
                <FaArrowRight size={20} />
            </div> */}
            {/* <div
                className="swiper-button-prev absolute left-4 top-1/2 transform -translate-y-1/2 p-3 bg-orange-500 text-white rounded-full shadow-lg transition-transform duration-300 hover:bg-orange-600 hover:scale-110"
                style={{ boxShadow: "0px 4px 10px rgba(255, 165, 0, 0.8)" }}
            >
                <FaArrowLeft size={20} />
            </div> */}
        </div>
    );
};

export default NewArrivalsSliders;
