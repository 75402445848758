import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../../src/styles/styles";
import Typical from "react-typical";
import backgroundVideo from "../../../Assests/HomeBanner.mp4";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Fade from 'react-reveal/Fade';
import textImage from "../../../Assests/textbg.jpg"; // Import the image for text
import { motion } from "framer-motion"; // Importing Framer Motion for animation

const Hero = () => {
  React.useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="relative h-[100vh] w-full flex flex-col items-center justify-center overflow-hidden ">
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
        <video autoPlay loop muted className="object-cover w-full h-full ">
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div
        className={`${styles.section} w-[90%] 800px:w-[60%] relative text-center`}
        data-aos="fade-up"
      >
        <Fade bottom>
          <motion.h1
            className="text-[60px] leading-[1.2] 800px:text-[100px] text-transparent font-[900] capitalize drop-shadow-lg bg-clip-text"
            style={{
              backgroundImage: `url(${textImage})`,
            }}
            animate={{ scale: [1, 1.1, 1] }} // Animation for scaling
            transition={{ duration: 2, repeat: Infinity, repeatType: "reverse" }}
          >
            Denzen Computers
          </motion.h1>
        </Fade>
        <Typical
          steps={[
            "Latest Models",
            1000,
            "Top Brands",
            1000,
            "Best Prices",
            1000,
          ]}
          loop={Infinity}
          wrapper="p"
          className="pt-5 text-[28px] font-[Poppins] font-[500] text-[#ffffffba] drop-shadow-lg"
        />
        <p className="pt-5 text-[24px] font-[Poppins] font-[400] text-[#ffffffba] drop-shadow-lg">
          Find the best laptops for your needs. Whether it's for work, gaming,
          or casual use, we have the right laptop for you. Explore our
          collection now!
        </p>
        <Link to="/products" className="inline-block">
          <Fade bottom>
            <div className={`${styles.button} mt-5 bg-[#F2703D]`}>
              <span className="text-[#fff] font-[Poppins] text-[22px]">
                Shop Now
              </span>
            </div>
          </Fade>
        </Link>

      </div>
    </div>
  );
};

export default Hero;
