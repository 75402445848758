import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/styles";
import ProductCard from "../ProductCard/ProductCard";
import Marquee from "react-fast-marquee";

const BestDeals = () => {
  const [data, setData] = useState([]);
  const { allProducts } = useSelector((state) => state.products);
  useEffect(() => {
    const allProductsData = allProducts ? [...allProducts] : [];
    const sortedData = allProductsData?.sort((a,b) => b.sold_out - a.sold_out); 
    const firstFive = sortedData && sortedData.slice(0, 5);
    setData(firstFive);
  }, [allProducts]);
  

  return (
    <div>
      <div className={`${styles.section}`}>
        <div className={`${styles.heading}`}>
        <div className=" w-full overflow-hidden">
        <Marquee speed={80} gradient={false} className="marquee-container">
        <span className="text-[105px] font-bold whitespace-nowrap">
          <span className="text-[#F2703D]">Best Deals for Gamers</span> &nbsp;
          <span className="text-[#1C1C1C]">Best Deals for Marketers</span> &nbsp;
          <span className="text-[#F2703D]">Best Deals for Programmers</span> &nbsp;
        </span>
      </Marquee>
      <style jsx>{`
        .marquee-container {
          display: flex;
          align-items: center;
          overflow: hidden;
        }
        .marquee-container:hover {
          animation-play-state: paused;
        }
      `}</style>
      </div>
        </div>
        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12 border-0">
           {
            data && data.length !== 0 &&(
              <>
               {data && data.map((i, index) => <ProductCard data={i} key={index} />)}
              </>
            )
           }
        </div>
      </div>
    </div>
  );
};

export default BestDeals;
